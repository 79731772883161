import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const ScrollIcon: React.FC = () => {
  return (
    <SvgIcon>
      <path d="M 8 2 C 6.3550302 2 5 3.3550302 5 5 L 5 16 L 2 16 L 2 19 C 2 20.64497 3.3550302 22 5 22 L 14 22 L 15 22 C 16.64497 22 18 20.64497 18 19 L 18 8 L 22 8 L 22 5 C 22 3.3550302 20.64497 2 19 2 L 8 2 z M 8 4 L 16.1875 4 C 16.07394 4.3148915 16 4.6481575 16 5 L 16 19 C 16 19.560181 15.571617 19.990667 15.013672 19.998047 L 15.013672 19.986328 C 14.450185 19.993939 14.007651 19.563085 14 19 L 13.972656 16.003906 L 13 16.013672 L 13 16 L 7 16 L 7 5 C 7 4.4349698 7.4349698 4 8 4 z M 19 4 C 19.56503 4 20 4.4349698 20 5 L 20 6 L 18 6 L 18 5 C 18 4.4349698 18.43497 4 19 4 z M 4 18 L 11.990234 18 L 12 19.025391 L 12 19.027344 C 12.004654 19.369889 12.081227 19.693696 12.193359 20 L 5 20 C 4.4349698 20 4 19.56503 4 19 L 4 18 z" />
    </SvgIcon>
  );
};

export { ScrollIcon };
