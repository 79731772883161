import ToggleButton from '@mui/material/ToggleButton';
import React from 'react';

const customToggleButton = {
  margin: '0 3px',
  border: 'none',
  marginLeft: '3px !important',
  color: '#000 !important',
  height: '30px',
  padding: '0px 4px',
};

const ToolbarToggleButton: React.FC<any> = (props) => {
  return (
    <ToggleButton size="small" {...props} sx={customToggleButton}>
      {props.children}
    </ToggleButton>
  );
};

export { ToolbarToggleButton };
