import { IconButton } from '@mui/material';
import React, { forwardRef } from 'react';

const ToolbarIconButton: React.FC<any> = forwardRef((props, ref) => {
  return (
    <IconButton edge={false} sx={{ color: '#000000', margin: '0 3px' }} size="small" {...props} ref={ref}>
      {props.children}
    </IconButton>
  );
});

export { ToolbarIconButton };
