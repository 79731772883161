import ClickAwayListener from '@mui/base/ClickAwayListener';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useDispatch } from 'react-redux';

import { trackEvent } from '../../../../services';
import { exportRules, exportXls } from '../../../../store/segment';
import { ToolbarIconButton } from '../ToolbarIconButton/ToolbarIconButton';

const useStyles = makeStyles(() => ({
  gutters: {
    padding: '4px 12px',
  },
}));

const menuItemStyle = {
  display: 'flex',
  justifyContent: 'space-between',
};

type ExportRulesMenuButtonProps = {
  disabled: boolean;
};

const ExportRulesMenuButton: React.FC<ExportRulesMenuButtonProps> = ({ disabled }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<any>(null);
  const dispatch = useDispatch();

  const handleMenuIconClick = (event: any) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleDownloadText = (event: any) => {
    event.stopPropagation();
    dispatch(exportRules());
    trackEvent.exportAsText();
    handleClose();
    setOpen(false);
  };

  const handleDownloadExcel = (event: any) => {
    event.stopPropagation();
    dispatch(exportXls());
    trackEvent.exportAsExcel();
    handleClose();
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const menuItems = [
    {
      label: 'text (.txt)',
      title: 'Export as text',
      onClick: handleDownloadText,
      ariaLabel: 'export rules as a text file',
    },
    {
      label: 'excel (.xlsx)',
      title: 'Export as excel',
      onClick: handleDownloadExcel,
      ariaLabel: 'export rules as an excel file',
    },
  ];

  return (
    <>
      <ToolbarIconButton
        aria-label="Download menu"
        onClick={handleMenuIconClick}
        title="Download"
        ref={anchorRef}
        disabled={disabled}
      >
        <GetAppIcon fontSize="small" />
      </ToolbarIconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        style={{ zIndex: 1999, position: 'relative' }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {menuItems.map(({ title, onClick, label, ariaLabel }) => {
                    return (
                      <MenuItem
                        onClick={(event: any) => onClick(event)}
                        sx={menuItemStyle}
                        classes={{
                          gutters: classes.gutters,
                        }}
                        key={label}
                        title={title}
                        aria-label={ariaLabel}
                      >
                        <span>{label}</span>
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export { ExportRulesMenuButton };
